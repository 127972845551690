var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var __assign = undefined && undefined.__assign || function () {
    __assign = Object.assign || function (t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];

        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }

      return t;
    };

    return __assign.apply(this || _global, arguments);
  }; // playground: stackblitz.com/edit/countup-typescript


  var CountUp =
  /** @class */
  function () {
    function CountUp(target, endVal, options) {
      var _this = this || _global;

      (this || _global).target = target;
      (this || _global).endVal = endVal;
      (this || _global).options = options;
      (this || _global).version = "2.0.8";
      (this || _global).defaults = {
        startVal: 0,
        decimalPlaces: 0,
        duration: 2,
        useEasing: true,
        useGrouping: true,
        smartEasingThreshold: 999,
        smartEasingAmount: 333,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      };
      (this || _global).finalEndVal = null; // for smart easing

      (this || _global).useEasing = true;
      (this || _global).countDown = false;
      (this || _global).error = "";
      (this || _global).startVal = 0;
      (this || _global).paused = true;

      (this || _global).count = function (timestamp) {
        if (!_this.startTime) {
          _this.startTime = timestamp;
        }

        var progress = timestamp - _this.startTime;
        _this.remaining = _this.duration - progress; // to ease or not to ease

        if (_this.useEasing) {
          if (_this.countDown) {
            _this.frameVal = _this.startVal - _this.easingFn(progress, 0, _this.startVal - _this.endVal, _this.duration);
          } else {
            _this.frameVal = _this.easingFn(progress, _this.startVal, _this.endVal - _this.startVal, _this.duration);
          }
        } else {
          if (_this.countDown) {
            _this.frameVal = _this.startVal - (_this.startVal - _this.endVal) * (progress / _this.duration);
          } else {
            _this.frameVal = _this.startVal + (_this.endVal - _this.startVal) * (progress / _this.duration);
          }
        } // don't go past endVal since progress can exceed duration in the last frame


        if (_this.countDown) {
          _this.frameVal = _this.frameVal < _this.endVal ? _this.endVal : _this.frameVal;
        } else {
          _this.frameVal = _this.frameVal > _this.endVal ? _this.endVal : _this.frameVal;
        } // decimal


        _this.frameVal = Number(_this.frameVal.toFixed(_this.options.decimalPlaces)); // format and print value

        _this.printValue(_this.frameVal); // whether to continue


        if (progress < _this.duration) {
          _this.rAF = requestAnimationFrame(_this.count);
        } else if (_this.finalEndVal !== null) {
          // smart easing
          _this.update(_this.finalEndVal);
        } else {
          if (_this.callback) {
            _this.callback();
          }
        }
      }; // default format and easing functions


      (this || _global).formatNumber = function (num) {
        var neg = num < 0 ? "-" : "";
        var result, x1, x2, x3;
        result = Math.abs(num).toFixed(_this.options.decimalPlaces);
        result += "";
        var x = result.split(".");
        x1 = x[0];
        x2 = x.length > 1 ? _this.options.decimal + x[1] : "";

        if (_this.options.useGrouping) {
          x3 = "";

          for (var i = 0, len = x1.length; i < len; ++i) {
            if (i !== 0 && i % 3 === 0) {
              x3 = _this.options.separator + x3;
            }

            x3 = x1[len - i - 1] + x3;
          }

          x1 = x3;
        } // optional numeral substitution


        if (_this.options.numerals && _this.options.numerals.length) {
          x1 = x1.replace(/[0-9]/g, function (w) {
            return _this.options.numerals[+w];
          });
          x2 = x2.replace(/[0-9]/g, function (w) {
            return _this.options.numerals[+w];
          });
        }

        return neg + _this.options.prefix + x1 + x2 + _this.options.suffix;
      };

      (this || _global).easeOutExpo = function (t, b, c, d) {
        return c * (-Math.pow(2, -10 * t / d) + 1) * 1024 / 1023 + b;
      };

      (this || _global).options = __assign(__assign({}, (this || _global).defaults), options);
      (this || _global).formattingFn = (this || _global).options.formattingFn ? (this || _global).options.formattingFn : (this || _global).formatNumber;
      (this || _global).easingFn = (this || _global).options.easingFn ? (this || _global).options.easingFn : (this || _global).easeOutExpo;
      (this || _global).startVal = this.validateValue((this || _global).options.startVal);
      (this || _global).frameVal = (this || _global).startVal;
      (this || _global).endVal = this.validateValue(endVal);
      (this || _global).options.decimalPlaces = Math.max((this || _global).options.decimalPlaces);
      this.resetDuration();
      (this || _global).options.separator = String((this || _global).options.separator);
      (this || _global).useEasing = (this || _global).options.useEasing;

      if ((this || _global).options.separator === "") {
        (this || _global).options.useGrouping = false;
      }

      (this || _global).el = typeof target === "string" ? document.getElementById(target) : target;

      if ((this || _global).el) {
        this.printValue((this || _global).startVal);
      } else {
        (this || _global).error = "[CountUp] target is null or undefined";
      }
    } // determines where easing starts and whether to count down or up


    CountUp.prototype.determineDirectionAndSmartEasing = function () {
      var end = (this || _global).finalEndVal ? (this || _global).finalEndVal : (this || _global).endVal;
      (this || _global).countDown = (this || _global).startVal > end;
      var animateAmount = end - (this || _global).startVal;

      if (Math.abs(animateAmount) > (this || _global).options.smartEasingThreshold) {
        (this || _global).finalEndVal = end;
        var up = (this || _global).countDown ? 1 : -1;
        (this || _global).endVal = end + up * (this || _global).options.smartEasingAmount;
        (this || _global).duration = (this || _global).duration / 2;
      } else {
        (this || _global).endVal = end;
        (this || _global).finalEndVal = null;
      }

      if ((this || _global).finalEndVal) {
        (this || _global).useEasing = false;
      } else {
        (this || _global).useEasing = (this || _global).options.useEasing;
      }
    }; // start animation


    CountUp.prototype.start = function (callback) {
      if ((this || _global).error) {
        return;
      }

      (this || _global).callback = callback;

      if ((this || _global).duration > 0) {
        this.determineDirectionAndSmartEasing();
        (this || _global).paused = false;
        (this || _global).rAF = requestAnimationFrame((this || _global).count);
      } else {
        this.printValue((this || _global).endVal);
      }
    }; // pause/resume animation


    CountUp.prototype.pauseResume = function () {
      if (!(this || _global).paused) {
        cancelAnimationFrame((this || _global).rAF);
      } else {
        (this || _global).startTime = null;
        (this || _global).duration = (this || _global).remaining;
        (this || _global).startVal = (this || _global).frameVal;
        this.determineDirectionAndSmartEasing();
        (this || _global).rAF = requestAnimationFrame((this || _global).count);
      }

      (this || _global).paused = !(this || _global).paused;
    }; // reset to startVal so animation can be run again


    CountUp.prototype.reset = function () {
      cancelAnimationFrame((this || _global).rAF);
      (this || _global).paused = true;
      this.resetDuration();
      (this || _global).startVal = this.validateValue((this || _global).options.startVal);
      (this || _global).frameVal = (this || _global).startVal;
      this.printValue((this || _global).startVal);
    }; // pass a new endVal and start animation


    CountUp.prototype.update = function (newEndVal) {
      cancelAnimationFrame((this || _global).rAF);
      (this || _global).startTime = null;
      (this || _global).endVal = this.validateValue(newEndVal);

      if ((this || _global).endVal === (this || _global).frameVal) {
        return;
      }

      (this || _global).startVal = (this || _global).frameVal;

      if (!(this || _global).finalEndVal) {
        this.resetDuration();
      }

      (this || _global).finalEndVal = null;
      this.determineDirectionAndSmartEasing();
      (this || _global).rAF = requestAnimationFrame((this || _global).count);
    };

    CountUp.prototype.printValue = function (val) {
      var result = this.formattingFn(val);

      if ((this || _global).el.tagName === "INPUT") {
        var input = (this || _global).el;
        input.value = result;
      } else if ((this || _global).el.tagName === "text" || (this || _global).el.tagName === "tspan") {
        (this || _global).el.textContent = result;
      } else {
        (this || _global).el.innerHTML = result;
      }
    };

    CountUp.prototype.ensureNumber = function (n) {
      return typeof n === "number" && !isNaN(n);
    };

    CountUp.prototype.validateValue = function (value) {
      var newValue = Number(value);

      if (!this.ensureNumber(newValue)) {
        (this || _global).error = "[CountUp] invalid start or end value: " + value;
        return null;
      } else {
        return newValue;
      }
    };

    CountUp.prototype.resetDuration = function () {
      (this || _global).startTime = null;
      (this || _global).duration = Number((this || _global).options.duration) * 1000;
      (this || _global).remaining = (this || _global).duration;
    };

    return CountUp;
  }();

  exports.CountUp = CountUp;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const CountUp = exports.CountUp,
      __esModule = exports.__esModule;